import React from 'react';
import Header from '../components/Header';

export default function About() {
  return (
    <div className="w-full min-h-screen bg-[#f9f9f8] flex flex-col items-center pb-[80px]">
      <div className="w-full max-w-[1440px] relative">
        <Header />
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
          <h1 className="text-4xl font-bold mb-4">About Us</h1>
          <p>Under development.</p>
        </div>
      </div>
    </div>
  );
}
