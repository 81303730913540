import React, { useRef, useEffect, useState } from 'react';
import Header from '../components/Header';
import CVUploadInterface from '../components/CVUploadInterface';

const fadeGif = new URL('../assets/gif/fade_gif.gif', import.meta.url);

export default function LandingPage() {
  const editorSectionRef = useRef(null);
  const [isGifLoaded, setIsGifLoaded] = useState(false);

  useEffect(() => {
    const img = new Image();
    img.src = fadeGif;
    img.onload = () => setIsGifLoaded(true);
  }, []);

  return (
    <div className="w-full min-h-screen bg-[#f9f9f8] flex flex-col items-center">
      <div className="w-full max-w-[1440px] relative">
        <Header />

        <div className="flex flex-col items-center mt-8">
          {/* Main Content Container - Tighter spacing */}
          <div className="flex flex-col items-center space-y-4">
            {/* "Don't fade" Container */}
            <div className="flex items-center justify-center w-full">
              {/* Adjusted text sizes and breakpoints */}
              <span className="text-[2.5rem] xs:text-[4rem] sm:text-[6rem] lg:text-[136px] 
                             text-[#202020] font-normal font-['Archivo'] leading-none 
                             tracking-tighter">
                Don't
              </span>

              {/* Fade Container - Adjusted spacing and sizes */}
              <div className="fade-animate relative ml-1 xs:ml-2 sm:ml-3 
                            transform-gpu">
                <div className="relative overflow-hidden
                              w-[150px] xs:w-[150px] sm:w-[200px] lg:w-[349px]
                              h-[50px] xs:h-[60px] sm:h-[90px] lg:h-[120px]
                              rounded-[8px] xs:rounded-[15px] sm:rounded-[20px] lg:rounded-[24px]
                              flex items-center justify-center">
                  {isGifLoaded && (
                    <img
                      src={fadeGif}
                      alt="Space background"
                      className="absolute inset-0 w-full h-full object-cover"
                    />
                  )}
                  
                  {/* Text Overlay - Matched sizing with "Don't" */}
                  <div className="relative z-10 flex items-center h-full">
                    <span className="text-[2.5rem] xs:text-[4rem] sm:text-[6rem] lg:text-[136px] 
                                   text-white font-normal font-['Archivo'] leading-none 
                                   tracking-tighter">
                      fade
                    </span>
                    <span className="text-[2.5rem] xs:text-[4rem] sm:text-[6rem] lg:text-[136px] 
                                   text-[#ce1f34] font-normal font-['Archivo'] leading-none 
                                   tracking-tighter">
                      .
                    </span>
                  </div>
                </div>
              </div>
            </div>

                {/* "Find the right match." - Reduced top margin */}
    <div className="w-full flex justify-center overflow-visible 
                    -mt-3 xs:-mt-4 sm:-mt-6 lg:-mt-6"> {/* Negative margin to pull it up */}
      <h1 className="text-[2.5rem] xs:text-[4rem] sm:text-[6rem] lg:text-[136px]
                   text-[#ababab] font-normal font-['Archivo'] 
                   leading-none tracking-tighter whitespace-nowrap">
        Find the right match.
      </h1>
    </div>
  </div>

          {/* Description Section - Adjusted spacing */}
          <div className="flex flex-col items-center gap-4 text-center 
                         max-w-[90%] sm:max-w-[80%] lg:max-w-[800px] 
                         mt-4 sm:mt-6 lg:mt-8">
            <p className="text-base sm:text-lg lg:text-xl text-[#0E0E0E] 
                         font-medium font-['Archivo'] leading-relaxed">
              We live in a time where information clutter covers the best information 
              for each of us. Whether that's understanding your skillset or re-making 
              your CV to pass AI checks.
            </p>

            {/* Start Now Button */}
            <button
              onClick={() => editorSectionRef.current?.scrollIntoView({ behavior: 'smooth' })}
              className="h-10 sm:h-11 lg:h-[42px] px-4 sm:px-5 lg:px-6 
                       border border-[#8f8f8f] rounded-[60px] 
                       flex items-center gap-2 
                       hover:bg-gray-200 transition-colors 
                       transform hover:scale-105 active:scale-100
                       mt-2"
            >
              <span className="text-sm sm:text-base lg:text-[15px] 
                             font-semibold font-['Archivo']">
                Start now
              </span>
              <svg className="w-3 h-3 sm:w-3.5 sm:h-3.5 lg:w-4 lg:h-4" 
                   viewBox="0 0 16 17" fill="none">
                <path d="M16 1.60175V13.1569C16 13.3927 15.9064 13.6188 15.7397 13.7855C15.573 13.9522 15.3469 14.0458 15.1111 14.0458C14.8754 14.0458 14.6493 13.9522 14.4826 13.7855C14.3159 13.6188 14.2223 13.3927 14.2223 13.1569V3.74724L1.51823 16.4524C1.35144 16.6192 1.12523 16.7129 0.889356 16.7129C0.653484 16.7129 0.427273 16.6192 0.260486 16.4524C0.0936996 16.2856 0 16.0594 0 15.8235C0 15.5877 0.0936996 15.3615 0.260486 15.1947L12.9656 2.49061H3.55594C3.3202 2.49061 3.09411 2.39697 2.92742 2.23027C2.76073 2.06358 2.66708 1.83749 2.66708 1.60175C2.66708 1.36601 2.76073 1.13993 2.92742 0.973232C3.09411 0.806538 3.3202 0.712891 3.55594 0.712891H15.1111C15.3469 0.712891 15.573 0.806538 15.7397 0.973232C15.9064 1.13993 16 1.36601 16 1.60175Z" 
                      fill="#343330"/>
              </svg>
            </button>
          </div>

          {/* Editor Section */}
          <div ref={editorSectionRef} 
               className="w-full max-w-[90%] sm:max-w-[85%] lg:max-w-[1201px]
                         h-[400px] sm:h-[550px] lg:h-[691px]
                         mt-8 sm:mt-10 lg:mt-12 
                         bg-[#171616] rounded-3xl overflow-hidden">
            <CVUploadInterface />
          </div>
        </div>
      </div>
    </div>
  );
}