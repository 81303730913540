import React, { useState, useRef, useEffect, useCallback } from 'react';
import { downloadPDF, updateCV, API_BASE_URL } from '../utils/api';
import wand from '../assets/images/magic_wand.svg';
import EditingControls from './EditingControls';

export default function CVPreview({ cvData, onUpdate }) {
  const { 
    pdf_urls: pdfUrls, 
    html_urls: htmlUrls,
    available_styles: availableStyles, 
    cv_set_id: cvSetId,
    recommendations = []
  } = cvData;
  
  const [selectedStyle, setSelectedStyle] = useState(availableStyles[0]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');
  const [isEditing, setIsEditing] = useState(false);
  const [editingPosition, setEditingPosition] = useState(null);
  const [activeRecommendations, setActiveRecommendations] = useState(recommendations);
  const [processedStyles, setProcessedStyles] = useState({});
  const previewRef = useRef(null);

  // Cache and process all styles on component mount
  useEffect(() => {
    const processAllStyles = async () => {
      const processed = {};
      for (const style of availableStyles) {
        try {
          const response = await fetch(`${API_BASE_URL}${htmlUrls[style]}`);
          const html = await response.text();
          processed[style] = html;
        } catch (error) {
          console.error(`Error processing style ${style}:`, error);
        }
      }
      setProcessedStyles(processed);
    };

    processAllStyles();
  }, [availableStyles, htmlUrls]);

  const handleEditorClick = useCallback((e) => {
    if (!isEditing) return;

    // Get the clicked element's content and position
    const rect = e.target.getBoundingClientRect();
    const iframeRect = previewRef.current.getBoundingClientRect();
    
    setEditingPosition({
      top: rect.top + iframeRect.top + window.scrollY,
      left: rect.right + iframeRect.left + 10,
    });
  }, [isEditing]);

  const setupPreview = useCallback(async () => {
    try {
      const html = processedStyles[selectedStyle];
      if (!html) return;
      
      const iframe = previewRef.current;
      if (!iframe) return;

      const iframeDoc = iframe.contentDocument || iframe.contentWindow.document;
      
      // Create a style element for the base iframe styles
      const baseStyle = document.createElement('style');
      baseStyle.textContent = `
        body { 
          margin: 0;
          padding: 32px;
          height: 100%;
          overflow-y: auto;
          background: white;
          font-family: Arial, sans-serif;
        }
        .suggestion {
          background-color: rgba(206, 31, 52, 0.1);
          border-left: 2px solid #ce1f34;
          padding-left: 8px;
          margin: 8px 0;
        }
      `;

      // Write the complete HTML
      iframeDoc.open();
      iframeDoc.write('<!DOCTYPE html><html><head></head><body></body></html>');
      iframeDoc.close();
      
      // Add the styles and content
      iframeDoc.head.appendChild(baseStyle);
      iframeDoc.body.innerHTML = html;

      // Set up editing if enabled
      if (isEditing) {
        iframeDoc.designMode = 'on';
        iframeDoc.addEventListener('click', handleEditorClick);
      }

    } catch (error) {
      console.error('Error setting up preview:', error);
      setError('Failed to load CV content');
    }
  }, [selectedStyle, processedStyles, isEditing, handleEditorClick]);

// Setup preview for selected style
useEffect(() => {
  if (!processedStyles[selectedStyle]) return;
  
  // Store ref value at the time the effect runs
  const iframe = previewRef.current;
  if (!iframe) return;
  
  const iframeDoc = iframe.contentDocument || iframe.contentWindow.document;
  
  setupPreview();

  // Cleanup function using the stored ref value
  return () => {
    if (iframeDoc) {
      iframeDoc.removeEventListener('click', handleEditorClick);
    }
  };
}, [selectedStyle, processedStyles, setupPreview, handleEditorClick]);

  const capitalizeWords = (str) =>
    str.replace(/\b\w/g, (char) => char.toUpperCase()).replace(/_/g, ' ');

  const handleStyleSelect = (style) => {
    if (!processedStyles[style]) return; // Disable if not processed
    
    setSelectedStyle(style);
    setError('');
    if (isEditing) {
      setIsEditing(false);
    }
  };

  const handleDownload = async () => {
    if (selectedStyle && pdfUrls[selectedStyle]) {
      try {
        const blob = await downloadPDF(pdfUrls[selectedStyle]);
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = `${selectedStyle}_CV.pdf`;
        link.click();
        URL.revokeObjectURL(link.href);
      } catch (error) {
        console.error('Error downloading CV:', error);
        setError('Failed to download CV');
      }
    }
  };

  const enableEditing = () => {
    const iframe = previewRef.current;
    if (iframe) {
      const iframeDoc = iframe.contentDocument || iframe.contentWindow.document;
      iframeDoc.designMode = 'on';
      iframeDoc.addEventListener('click', handleEditorClick);
      setIsEditing(true);
      iframe.style.outline = '2px solid #ce1f34';
    }
  };

  const disableEditing = () => {
    const iframe = previewRef.current;
    if (iframe) {
      const iframeDoc = iframe.contentDocument || iframe.contentWindow.document;
      iframeDoc.designMode = 'off';
      iframeDoc.removeEventListener('click', handleEditorClick);
      setIsEditing(false);
      iframe.style.outline = 'none';
      setEditingPosition(null);
    }
  };

  const applyRecommendation = (recommendation) => {
    const iframe = previewRef.current;
    if (!iframe) return;

    const doc = iframe.contentDocument || iframe.contentWindow.document;
    
    // Apply the recommendation based on its category
    switch (recommendation.category) {
      case 'Keywords':
        doc.execCommand('foreColor', false, '#ce1f34');
        break;
      case 'Structure':
        doc.execCommand('insertHTML', false, `<div class="suggestion">${recommendation.suggestion}</div>`);
        break;
      default:
        doc.execCommand('insertText', false, recommendation.suggestion);
    }

    // Remove the applied recommendation
    setActiveRecommendations(prev => 
      prev.filter(rec => rec.suggestion !== recommendation.suggestion)
    );
  };

  const handleSaveChanges = async () => {
    try {
      setIsLoading(true);
      setError('');

      const iframe = previewRef.current;
      if (!iframe) {
        throw new Error('Preview not available');
      }

      const iframeDoc = iframe.contentDocument || iframe.contentWindow.document;
      const updatedHtml = iframeDoc.documentElement.outerHTML;

      const result = await updateCV(cvSetId, selectedStyle, updatedHtml);
      if (result.success) {
        onUpdate(result);
        disableEditing();
      } else {
        throw new Error('Failed to update CV');
      }
    } catch (error) {
      console.error('Error saving changes:', error);
      setError(error.message || 'Failed to save changes');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="w-[1201px] h-[691px] bg-[#171616] rounded-3xl p-[56px]"
         style={{
           backgroundImage: `radial-gradient(circle at center, rgba(255, 255, 255, 0.1) 1px, transparent 1px)`,
           backgroundSize: '16px 16px',
         }}>
      <div className="flex w-full h-full">
        <div className="w-[368.87px] flex flex-col mr-6">
          <div className="mb-10">
            <div className="flex items-start gap-2 mb-3">
              <div className="text-[#f4f4f4] text-6xl font-medium font-['Archivo'] leading-tight">
                Edit away
              </div>
              <img src={wand} alt="magic wand" className="w-12 h-12 relative top-2" />
            </div>
          </div>

          <div className="flex flex-col gap-4">
            <div className="text-[#b0b0b0] text-sm">Choose CV template:</div>
            <div className="flex flex-wrap gap-3">
              {availableStyles.map((style) => (
                <button
                  key={style}
                  onClick={() => handleStyleSelect(style)}
                  disabled={!processedStyles[style] || isLoading}
                  className={`
                    w-[164px] h-10 p-3
                    transition-colors duration-200
                    rounded-[60px] shadow-inner border border-[#e0e0e0] 
                    flex items-center justify-center
                    ${selectedStyle === style 
                      ? 'bg-black text-white hover:bg-black' 
                      : 'bg-white text-black hover:bg-gray-50'
                    }
                    ${(!processedStyles[style] || isLoading) ? 'opacity-50 cursor-not-allowed' : 'cursor-pointer'}
                  `}
                >
                  <span className="text-xs font-medium font-['Archivo'] leading-[16.85px] text-center w-full whitespace-nowrap">
                    {!processedStyles[style] ? 'Loading...' : capitalizeWords(style)}
                  </span>
                </button>
              ))}
            </div>

            {error && (
              <div className="text-red-500 text-sm">
                {error}
              </div>
            )}

            {activeRecommendations.length > 0 && (
              <div className="mt-4">
                <div className="text-[#b0b0b0] text-sm mb-2">
                  Remaining Recommendations ({activeRecommendations.length}):
                </div>
                <div className="max-h-[200px] overflow-y-auto">
                  {activeRecommendations.map((rec, index) => (
                    <div key={index} className="bg-white/10 rounded-lg p-3 mb-2">
                      <div className="text-white text-sm font-semibold">{rec.category}</div>
                      <div className="text-[#b0b0b0] text-sm">{rec.suggestion}</div>
                    </div>
                  ))}
                </div>
              </div>
            )}
            
            <div className="flex gap-3">
              {!isEditing ? (
                <button
                  onClick={enableEditing}
                  disabled={isLoading}
                  className="flex-1 p-3 bg-[#ce1f34] rounded-[60px] text-white text-center font-semibold disabled:opacity-50"
                >
                  Enable Editing
                </button>
              ) : (
                <button
                  onClick={handleSaveChanges}
                  disabled={isLoading}
                  className="flex-1 p-3 bg-[#ce1f34] rounded-[60px] text-white text-center font-semibold disabled:opacity-50"
                >
                  {isLoading ? 'Saving...' : 'Save Changes'}
                </button>
              )}

              <button
                onClick={handleDownload}
                disabled={isLoading}
                className="flex-1 p-3 bg-white rounded-[60px] text-black text-center font-semibold disabled:opacity-50"
              >
                Download CV
              </button>
            </div>
          </div>
        </div>

        <div className="w-[656px] h-[676px] bg-white rounded-2xl shadow-inner border border-[#e0e0e0] ml-auto overflow-hidden">
          <iframe
            ref={previewRef}
            title="CV Preview"
            className="w-full h-full"
            style={{
              border: 'none',
              background: 'white',
              transition: 'outline 0.2s ease'
            }}
          />
        </div>

        {isEditing && editingPosition && (
          <EditingControls
            recommendations={activeRecommendations}
            onApply={applyRecommendation}
            position={editingPosition}
          />
        )}
      </div>
    </div>
  );
}