// src/utils/api.js

// Use environment variable with fallback for local development
export const API_BASE_URL = process.env.REACT_APP_API_URL || 'http://localhost:8000/api';

/**
 * Common fetch wrapper with error handling
 * @param {string} endpoint - The API endpoint
 * @param {Object} options - Fetch options
 * @returns {Promise<Object>} - The JSON response
 */
const fetchAPI = async (endpoint, options = {}) => {
  try {
    const response = await fetch(`${API_BASE_URL}${endpoint}`, {
      ...options,
      // Add any common headers here
      headers: {
        ...options.headers,
      },
    });

    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData.detail || `HTTP error! status: ${response.status}`);
    }

    return response;
  } catch (error) {
    console.error(`API Error: ${error.message}`);
    throw error;
  }
};

/**
 * Generates an optimized CV based on the uploaded file and job description.
 * @param {File} file - The uploaded CV file (PDF).
 * @param {string} jobDescription - The job description text.
 * @returns {Promise<Object>} - The JSON response containing CV styles and URLs.
 */
export const generateCV = async (file, jobDescription) => {
  const formData = new FormData();
  formData.append('cv', file);
  formData.append('job_description', jobDescription);

  const response = await fetchAPI('/cv/generate', {
    method: 'POST',
    body: formData,
  });

  return response.json();
};

/**
 * Updates the CV with edited HTML content
 * @param {string} cvSetId - The CV set identifier
 * @param {string} style - The selected style name
 * @param {string} htmlContent - The updated HTML content
 * @returns {Promise<Object>} - The JSON response containing the updated CV data
 */
export const updateCV = async (cvSetId, style, htmlContent) => {
  const response = await fetchAPI(`/cv/${cvSetId}/update/${style}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ html_content: htmlContent }),
  });

  return response.json();
};

/**
 * Downloads a PDF file from the given relative URL.
 * @param {string} url - The relative URL to the PDF file.
 * @returns {Promise<Blob>} - The PDF file as a Blob.
 */
export const downloadPDF = async (url) => {
  const response = await fetchAPI(url, {
    method: 'GET',
  });

  return response.blob();
};