// src/components/EditingControls.js
import React from 'react';

export default function EditingControls({ recommendations, onApply, position }) {
  // Group recommendations by category
  const groupedRecs = recommendations.reduce((acc, rec) => {
    if (!acc[rec.category]) {
      acc[rec.category] = [];
    }
    acc[rec.category].push(rec);
    return acc;
  }, {});

  return (
    <div 
      className="absolute bg-white rounded-lg shadow-lg border border-gray-200 p-4 z-50"
      style={position}
    >
      <div className="text-sm font-semibold mb-2">Editing Suggestions</div>
      {Object.entries(groupedRecs).map(([category, recs]) => (
        <div key={category} className="mb-4">
          <div className="text-xs font-medium text-gray-500 mb-1">{category}</div>
          {recs.map((rec, index) => (
            <div 
              key={index}
              className="flex items-center gap-2 mb-2 hover:bg-gray-50 p-2 rounded cursor-pointer"
              onClick={() => onApply(rec)}
            >
              <div className="w-1.5 h-1.5 rounded-full" 
                style={{
                  backgroundColor: rec.priority >= 4 ? '#ce1f34' : 
                                 rec.priority >= 3 ? '#f59e0b' : '#10b981'
                }}
              />
              <div className="text-xs">{rec.suggestion}</div>
            </div>
          ))}
        </div>
      ))}
    </div>
  );
}