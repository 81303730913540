import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import logo from '../assets/images/role_match_logo.svg';

export default function Header() {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  return (
    <header className="w-full px-4 md:px-6 py-4 flex items-center justify-between max-w-[1440px] mx-auto relative z-10">
      {/* Logo Section - Adjusted sizes and demo visibility */}
      <div className="flex items-center gap-2 sm:gap-3">
        <Link to="/" className="w-[90px] xs:w-[100px] sm:w-[120px] md:w-[180px] h-auto">
          <img 
            src={logo} 
            alt="role match logo" 
            className="w-full h-full object-contain"
          />
        </Link>
        {/* Demo badge - Always visible but sized appropriately */}
        <div className="flex px-2 sm:px-3 py-0.5 sm:py-1 bg-[#efc3c8]/30 border border-[#ce1f34] rounded-full">
          <span className="text-[#ce1f34] text-[10px] xs:text-xs font-medium font-['Basier Square']">
            DEMO V1
          </span>
        </div>
      </div>

      {/* Mobile Menu Button - Adjusted positioning */}
      <button 
        className="md:hidden p-2 -mr-2"
        onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}
        aria-label={isMobileMenuOpen ? "Close menu" : "Open menu"}
      >
        <svg 
          className="w-5 h-5 xs:w-6 xs:h-6 text-[#20263c]" 
          fill="none" 
          stroke="currentColor" 
          viewBox="0 0 24 24"
        >
          {isMobileMenuOpen ? (
            <path 
              strokeLinecap="round" 
              strokeLinejoin="round" 
              strokeWidth={2} 
              d="M6 18L18 6M6 6l12 12" 
            />
          ) : (
            <path 
              strokeLinecap="round" 
              strokeLinejoin="round" 
              strokeWidth={2} 
              d="M4 6h16M4 12h16M4 18h16" 
            />
          )}
        </svg>
      </button>

      {/* Desktop Navigation */}
      <nav className="hidden md:flex">
        <div className="w-[300px] lg:w-[400px] h-[40px] bg-[#dcdcdc]/80 rounded-3xl 
                       shadow-md backdrop-blur-[20px] flex items-center justify-between px-4">
          <NavItem text="About" disabled showTooltip />
          <NavItem text="Dashboard" disabled showTooltip />
          <NavItem text="Log in" disabled showTooltip />
          <TooltipButton text="Sign up" showTooltip />
        </div>
      </nav>

      {/* Mobile Menu Overlay - Improved styling */}
      {isMobileMenuOpen && (
        <div className="absolute top-full left-0 right-0 bg-white/95 backdrop-blur-sm 
                       shadow-lg md:hidden z-20 mt-2 rounded-lg border border-gray-100">
          <div className="flex flex-col p-4 space-y-4">
            <NavItem text="About" disabled showTooltip />
            <NavItem text="Dashboard" disabled showTooltip />
            <NavItem text="Log in" disabled showTooltip />
            <TooltipButton text="Sign up" showTooltip />
          </div>
        </div>
      )}
    </header>
  );
}

function NavItem({ to, text, disabled, showTooltip }) {
  const [show, setShow] = useState(false);

  return disabled ? (
    <div 
      onMouseEnter={() => setShow(true)} 
      onMouseLeave={() => setShow(false)}
      className="relative text-[#20263c] text-[15px] font-medium font-['Basier Square'] 
                 opacity-50 cursor-default"
    >
      {text}
      {showTooltip && show && (
        <div className="absolute bottom-full mb-1 w-max px-2 py-1 
                       text-xs text-white bg-black rounded-md">
          Coming Soon
        </div>
      )}
    </div>
  ) : (
    <Link 
      to={to} 
      className="text-[#20263c] text-[15px] font-medium font-['Basier Square']"
    >
      {text}
    </Link>
  );
}

function TooltipButton({ text, showTooltip }) {
  const [show, setShow] = useState(false);

  return (
    <div
      onMouseEnter={() => setShow(true)}
      onMouseLeave={() => setShow(false)}
      className="relative"
    >
      <button 
        className="bg-[#2b2b2b] text-white px-4 py-[6px] rounded-xl 
                   text-[15px] font-medium font-['Basier Square'] 
                   opacity-50 cursor-default"
      >
        {text}
      </button>
      {showTooltip && show && (
        <div className="absolute bottom-full mb-1 w-max px-2 py-1 
                       text-xs text-white bg-black rounded-md">
          Coming Soon
        </div>
      )}
    </div>
  );
}