import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { AuthProvider } from './contexts/AuthContext';
import LandingPage from './pages/LandingPage';
import LoginPage from './pages/LoginPage';
import SignupPage from './pages/SignupPage';
import GoogleCallback from './pages/GoogleCallback';
import About from './pages/About';
import Dashboard from './pages/Dashboard';

function App() {
  return (
    <AuthProvider>
      <Router>
        <div className="min-h-screen bg-[#e7e7e7]">
          <Routes>
            <Route path="/" element={<LandingPage />} />
            <Route path="/landingpage" element={<LandingPage />} />
            <Route path="/login" element={<LoginPage />} />
            <Route path="/signup" element={<SignupPage />} />
            <Route path="/auth/callback" element={<GoogleCallback />} />
            <Route path="/about" element={<About />} />
            <Route
              path="/dashboard"
              element={
                  <Dashboard />

              }
            />
          </Routes>
        </div>
      </Router>
    </AuthProvider>
  );
}

export default App;