import React, { useState } from 'react';
import wand from '../assets/images/magic_wand.svg';
import uploadPlus from '../assets/images/upload_plus.svg'; 
import { generateCV } from '../utils/api';
import CVPreview from './CVPreview';

export default function CVUploadInterface() {
  const [file, setFile] = useState(null);
  const [jobDescription, setJobDescription] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');
  const [cvData, setCvData] = useState(null);

  const handleSubmit = async () => {
    if (!file || !jobDescription) {
      setError('Please upload a CV and enter a job description');
      return;
    }

    setIsLoading(true);
    setError('');

    try {
      const data = await generateCV(file, jobDescription);
      if (data.success) {
        setCvData(data);
      } else {
        throw new Error('Failed to generate CV');
      }
    } catch (error) {
      console.error('Error:', error);
      setError(error.message || 'An error occurred while generating the CV');
    } finally {
      setIsLoading(false);
    }
  };

  const handleCVUpdate = (newData) => {
    setCvData(newData);
  };

  // Render CVPreview if cvData is available
  if (cvData) {
    return <CVPreview cvData={cvData} onUpdate={handleCVUpdate} />;
  }

  return (
    <div 
      className="w-[1201px] h-[691px] bg-[#171616] rounded-3xl p-[56px]"
      style={{
        backgroundImage: `radial-gradient(circle at center, rgba(255, 255, 255, 0.1) 1px, transparent 1px)`,
        backgroundSize: '16px 16px',
      }}
    >
      <div className="flex w-full h-full">
        {/* Left Panel */}
        <div className="w-[368.87px] flex flex-col mr-6">
          {/* Header */}
          <div className="mb-10">
            <div className="flex items-start gap-2 mb-3">
              <div className="text-[#f4f4f4] text-6xl font-medium font-['Archivo'] leading-tight">
                Edit away
              </div>
              <img 
                src={wand} 
                alt="magic wand" 
                className="w-12 h-12 relative top-2"
              />
            </div>
            <div className="text-[#b0b0b0] text-sm font-normal font-['Archivo'] leading-tight">
              Upload your CV and paste below the job description to get a tailored CV.
            </div>
          </div>

          {/* Form Section */}
          <div className="flex flex-col gap-4">
            {/* File Upload */}
            <label 
              className={`h-[67px] w-full bg-white rounded-2xl shadow-inner cursor-pointer 
                hover:bg-gray-50 transition-colors ${isLoading ? 'opacity-50 cursor-not-allowed' : ''}`}
            >
              <div className="h-full px-6 flex items-center gap-3">
                <img 
                  src={uploadPlus} 
                  alt="Upload icon" 
                  className="w-[29px] h-[29px]" 
                />
                <span className="text-[#2f2f2f] text-xl font-medium font-['Basier Square']">
                  {file ? file.name : 'Upload CV'}
                </span>
              </div>
              <input
                type="file"
                className="hidden"
                onChange={(e) => {
                  if (!isLoading) {
                    const selectedFile = e.target.files?.[0];
                    if (selectedFile && selectedFile.type === 'application/pdf') {
                      setFile(selectedFile);
                      setError('');
                    } else {
                      setError('Please upload a PDF file');
                    }
                  }
                }}
                accept=".pdf"
                disabled={isLoading}
              />
            </label>

            {/* Job Description Input */}
            <textarea
              className={`w-full h-[210px] p-6 bg-white rounded-2xl shadow-inner border 
                border-[#d9d9d0] text-sm font-normal font-['Archivo'] resize-none 
                focus:outline-none placeholder-[#82828270] ${isLoading ? 'opacity-50 cursor-not-allowed' : ''}`}
              placeholder="Insert job description"
              value={jobDescription}
              onChange={(e) => setJobDescription(e.target.value)}
              disabled={isLoading}
            />

            {/* Error Message */}
            {error && (
              <div className="text-red-500 text-sm">
                {error}
              </div>
            )}

            {/* Submit Button */}
            <div className="mt-4 flex justify-start">
              <button 
                onClick={handleSubmit}
                disabled={isLoading || !file || !jobDescription}
                className={`w-32 h-[42px] p-4 bg-white rounded-[60px] border border-[#8f8f8f] 
                  justify-center items-center gap-2 inline-flex ${
                    isLoading || !file || !jobDescription 
                      ? 'opacity-50 cursor-not-allowed' 
                      : 'cursor-pointer hover:bg-gray-200 transition-colors'
                  }`}
              >
                <span className="text-center text-black text-[15px] font-semibold font-['Archivo']">
                  {isLoading ? 'Generating...' : 'Generate'}
                </span>
                {!isLoading && (
                  <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path 
                      d="M16 1.60175V13.1569C16 13.3927 15.9064 13.6188 15.7397 13.7855C15.573 13.9522 
                        15.3469 14.0458 15.1111 14.0458C14.8754 14.0458 14.6493 13.9522 14.4826 13.7855C14.3159 
                        13.6188 14.2223 13.3927 14.2223 13.1569V3.74724L1.51823 16.4524C1.35144 16.6192 1.12523 
                        16.7129 0.889356 16.7129C0.653484 16.7129 0.427273 16.6192 0.260486 16.4524C0.0936996 
                        16.2856 0 16.0594 0 15.8235C0 15.5877 0.0936996 15.3615 0.260486 15.1947L12.9656 
                        2.49061H3.55594C3.3202 2.49061 3.09411 2.39697 2.92742 2.23027C2.76073 2.06358 2.66708 
                        1.83749 2.66708 1.60175C2.66708 1.36601 2.76073 1.13993 2.92742 0.973232C3.09411 0.806538 
                        3.3202 0.712891 3.55594 0.712891H15.1111C15.3469 0.712891 15.573 0.806538 15.7397 
                        0.973232C15.9064 1.13993 16 1.36601 16 1.60175Z" 
                      fill="#343330"
                    />
                  </svg>
                )}
              </button>
            </div>
          </div>
        </div>

        {/* Right Panel - Preview Area */}
        <div className="w-[656px] h-[676px] bg-[#303030] rounded-2xl shadow-inner border border-[#e0e0e0] ml-auto overflow-hidden">
          <div className="w-full h-full flex items-center justify-center text-white text-lg font-medium font-['Archivo']">
            {isLoading ? (
              <div className="flex flex-col items-center gap-4">
                <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-white"></div>
                <span>Generating your CV...</span>
              </div>
            ) : (
              <div className="text-center px-8">
                <p className="text-sm text-gray-400 mt-2">
                  Upload your CV and provide a job description to get started
                </p>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}